<template>
  <div class="container">
    <div class="search-box mb10">
      <van-search v-model="keyword" placeholder="搜索" />
    </div>
    <div class="main">

       <div class="cards">
          <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
            <div v-for="(item,index) in list" :key="index" class="card" @click="onItemClick(index)">
              <div class="card-hd van-hairline--bottom">
                <div class="card-title van-ellipsis">
                  {{ item.fee_name }} - {{ item.studio_name }}
                </div>
                <!-- <div class="card-value van-ellipsis">
                  {{ item.contract_no }}
                </div> -->
                <van-icon name="arrow" :size="16" color="#999" />
              </div>
              <div class="card-bd">
                <div class="card-bd-item">
                  <div class="card-bd-item-title">{{ item.contract_times }}</div>
                  <div class="card-bd-item-txt">购买合计</div>
                </div>
                <div class="card-bd-item">
                  <div class="card-bd-item-title">
                    <a href="javascript:void(0);" style="color:#00DE93;">{{ item.left_total }}</a>
                  </div>
                  <div class="card-bd-item-txt">可用</div>
                </div>
                <div class="card-bd-item">
                  <div class="card-bd-item-title">{{ item.contract_times  -  item.left_total}}</div>
                  <div class="card-bd-item-txt">已使用</div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <!-- <div v-else class="no-data">
          <van-empty description="暂无课程信息~" />
        </div> -->

      <!-- <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div class="contract-list">
          <div v-for="item in list" :key="item.contract_id" class="contract-list-item">
            <div class="contract-list-item-hd">
              签约合同：{{ item.contract_no }}
            </div>
            <div class="contract-list-item-bd">
              <div class="contract-list-item-title">签约校区：{{ item.studio_name }}</div>
              <div class="contract-list-item-txt">签约课时： {{ item.contract_times }}</div>
              <div class="contract-list-item-txt">可用课时： {{ item.left_total }}</div>
              <div class="contract-list-item-txt">课程科目：{{ item.course_cate_id }}</div>
              <div class="contract-list-item-txt" v-if="item.course_consultant">课程顾问：{{ item.course_consultant }}</div>

              <van-progress :percentage="(item.left_precent)" :show-pivot="false" color="#00cca2" stroke-width="8" />
              <div class="contract-list-item-btn">
                <van-button type="primary" size="small">{{ item.fee_type_name }}</van-button>
              </div>

            </div>
            <div class="contract-list-item-ft">
              签约日期：{{ item.contract_date }}
            </div>
          </div>
        </div>
      </van-list> -->
    </div>
    <van-popup v-model="show" round position="bottom" :style="{ height: '80%' }">
        <van-cell v-for="(sub_item,index) in contract_log" :key='index' size="small" :value="sub_item.transaction_amount">
            <template #title>
              <span class="custom-title" >{{index+1}}. {{sub_item.course_class_time}}</span>
            </template>
        </van-cell>

        <van-empty v-if="contract_log.length==0" description="暂无扣课记录~" />
    </van-popup>
  </div>
</template>
<script>

import { Search ,Progress, Empty ,Popup} from 'vant'
import PageMixin from '@/mixins/page'
import { debounce } from '@/common/util'

export default {
  name: 'StudentContract',
  components: {
    [Search.name]: Search,
    [Empty.name]: Empty,
    [Popup.name]: Popup,
    [Progress.name]: Progress,
  },
  mixins: [PageMixin],
  data() {
    return {
      keyword: '',
      show: false,
      list: [],
      contract_log:[],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  watch: {
    keyword(val) {
      this.search()
    }
  },
  created() {
    // this.onListLoad()
  },
  methods: {
    onItemClick(index){
      this.onDetail(this.list[index].contract_id)
      this.show = true;
      console.log(index)
    },
    onListLoad() {
      this.onPage()
    },
    search: debounce(function() {
      this.pageCurrent = 1
      this.onPage()
    }, 300),
    onPage() {
      this.$api.contract_index({ q: this.keyword, 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    },
    onDetail(contract_id){
      this.$api.contract_log({contract_id:contract_id}).then(res => {
        this.contract_log = res.data.items;
      })
    }
  }
}
</script>
<style lang="less" scoped>

  .tab{
    margin-bottom: 10px;
  }
  .contract-list{

  }
  .contract-list-item{
    box-sizing: border-box;
    background: #fff;
    padding: 0 15px;
    margin-bottom: 10px;
    &-hd{
      padding: 10px 0;
      font-size: 14px;
      color: #333;
    }
    &-bd{
      position: relative;
      padding: 10px 0;
      border-top: 1PX solid @ui-hairline-color;
      border-bottom: 1PX solid @ui-hairline-color;
    }
    &-ft{
      padding: 8px 0;
      font-size: 14px;
      color: #666;
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
    &-txt{
      font-size: 14px;
      color: #999;
    }
    &-btn{
        position: absolute;
        top: 20px;
        right: 10px;
      }
  }

.van-cell{
    position: relative;
}
.van-cell-group{
    width: 96%;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
}
.van-cell__title{
    flex: 4;
}
.van-cell__label{
    display: flex;
    font-size: 11px;
    align-items: baseline;
}

</style>
<style lang="less" scoped>

  .style-box() {
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
  }

  .header {
    box-sizing: border-box;
    width: 100%;
    height: 277px;
    color: #fff;
    padding: 0 15px;
    // background-color: #fe4140;
    padding-top: 70px;
    background: @linear-gradient-color;
    background-image: url('~@/assets/user_bg.png');
    background-size: 100% 100%;
    &-media {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }
    &-title {
      font-size: 18px;
    }
    &-setting {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 24px;
    }
  }

  .header-block{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    &-item{
      position: relative;
      margin-right: 10px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      background: #fff;
      flex-shrink: 0;
    }
    &-item--first{
      // position: relative;
      // margin-right: 10px;
      // width: 80px;
      // height: 80px;
      // border-radius: 50%;
      // overflow: hidden;
      // background: #fff;
    }
    &-media {
      width: 100%;
      height: 100%;
    }
    &-title {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 10px;
      line-height: 1.8;
      color: #fff;
      background: rgba(0,0,0,.3);
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
      word-wrap:normal
    }
  }
  .group {
    .style-box();
    margin: 0 15px 15px 15px;
    &-inner{
      padding:10px 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }
    &-item{
      flex: 1;
      text-align: center;
    }
  }
  .balance-group {
    .style-box();
    .van-row {
      text-align: center;
    }
    .van-col {
      text-align: center;
    }
    &-title {
      font-size: 18px;
      font-weight: bold;
      color: #000;
      margin-bottom: 5px;
    }
    &-txt {
      font-size: 12px;
      color: #999;
    }
  }
  .changy-group {
    /deep/ .van-grid-item__icon{
      position: relative;
      font-size: 20px;
    }
    // .van-icon {
    //   font-size: 20px;
    // }
    // .van-col {
    //   margin-bottom:10px;
    // }
  }

  .user-gruop {
    &-name {
      // padding: 10px 0;
      color: #333;
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  .user-section {
    .style-box();
    margin: 0 20px 15px 20px;
    &-title {
      font-size: 14px;
      color: #000;
      font-weight: bold;
    }
    &-hd {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 0px;
      overflow: hidden;
      color: #323233;
      font-size: 14px;
      line-height: 24px;
      background-color: #fff;
      display: flex;
      align-items: center;
    }
    &-bd {
      box-sizing: border-box;
      width: 100%;
      padding: 15px 0;
    }
    &-grid {
      box-sizing: border-box;
      width: 20%;
      float: left;
      text-align: center;
      .van-icon {
        font-size: 24px;
        margin-bottom: 5px;
      }
      &-img {
        display: inline-block;
        width: 30px;
        height: 30px;
        // margin-bottom: 5px;
      }
      &-title {
        color: #333;
        font-size: 12px;
      }
    }
  }

  .card{
    .style-box();
    margin: 0 15px 15px 15px;
    &-hd{
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      font-size: 14px;

    }
    &-title{
      max-width: 80%;
    }
    &-bd{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-item{
        text-align: center;
        flex: 1;
        padding: 10px 0;
        &-title{
          font-size: 18px;
          font-weight: bold;
          color: #333;
          margin-bottom: 10px;
        }
        &-txt{
          font-size: 14px;
          color: #666;
        }
      }
    }
  }
</style>

